import { domReady } from "@roots/sage/client";
import Alpine from "alpinejs";
// import AOS from 'aos';
import Swiper, { Navigation, Pagination } from "swiper/bundle";
// import LazyLoad from "vanilla-lazyload";

/**
 * app.main
 */
const main = async (err) => {
  if (err) {
    // handle hmr errors
    console.error(err);
  }

  // application code
  window.Alpine = Alpine;
  // AOS.init({
  //   duration: 1200,
  //   once: true,
  //   disable: function() {
  //     return /bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent);
  //   },
  // })
  window.Swiper = Swiper;

  Alpine.start();

  initProjectOverlayClick();
  initSlideCardClick();
  initModals();
//   var lazyLoadInstance = new LazyLoad({
//     // Your custom settings go here
//   });

//   window.lazyLoadInstance = lazyLoadInstance;
};

/**
 * Initialize
 *
 * @see https://webpack.js.org/api/hot-module-replacement
 */
domReady(main);
import.meta.webpackHot?.accept(main);

(window.Components = {}),
  (window.Components.listbox = function (e) {
    return {
      init() {
        (this.optionCount = this.$refs.listbox.children.length),
          this.$watch("activeIndex", (e) => {
            this.open &&
              (null !== this.activeIndex
                ? (this.activeDescendant =
                    this.$refs.listbox.children[this.activeIndex].id)
                : (this.activeDescendant = ""));
          });
      },
      activeDescendant: null,
      optionCount: null,
      open: !1,
      activeIndex: null,
      selectedIndex: 0,
      get active() {
        return this.items[this.activeIndex];
      },
      get [e.modelName || "selected"]() {
        return this.items[this.selectedIndex];
      },
      choose(e) {
        (this.selectedIndex = e), (this.open = !1);
      },
      onButtonClick() {
        this.open ||
          ((this.activeIndex = this.selectedIndex),
          (this.open = !0),
          this.$nextTick(() => {
            this.$refs.listbox.focus(),
              this.$refs.listbox.children[this.activeIndex].scrollIntoView({
                block: "nearest",
              });
          }));
      },
      onOptionSelect() {
        null !== this.activeIndex && (this.selectedIndex = this.activeIndex),
          (this.open = !1),
          this.$refs.button.focus();
      },
      onEscape() {
        (this.open = !1), this.$refs.button.focus();
      },
      onArrowUp() {
        (this.activeIndex =
          this.activeIndex - 1 < 0
            ? this.optionCount - 1
            : this.activeIndex - 1),
          this.$refs.listbox.children[this.activeIndex].scrollIntoView({
            block: "nearest",
          });
      },
      onArrowDown() {
        (this.activeIndex =
          this.activeIndex + 1 > this.optionCount - 1
            ? 0
            : this.activeIndex + 1),
          this.$refs.listbox.children[this.activeIndex].scrollIntoView({
            block: "nearest",
          });
      },
      ...e,
    };
  }),
  (window.Components.menu = function (
    e = {
      open: !1,
    }
  ) {
    return {
      init() {
        (this.items = Array.from(
          this.$el.querySelectorAll('[role="menuitem"]')
        )),
          this.$watch("open", () => {
            this.open && (this.activeIndex = -1);
          });
      },
      activeDescendant: null,
      activeIndex: null,
      items: null,
      open: e.open,
      focusButton() {
        this.$refs.button.focus();
      },
      onButtonClick() {
        (this.open = !this.open),
          this.open &&
            this.$nextTick(() => {
              this.$refs["menu-items"].focus();
            });
      },
      onButtonEnter() {
        (this.open = !this.open),
          this.open &&
            ((this.activeIndex = 0),
            (this.activeDescendant = this.items[this.activeIndex].id),
            this.$nextTick(() => {
              this.$refs["menu-items"].focus();
            }));
      },
      onArrowUp() {
        if (!this.open)
          return (
            (this.open = !0),
            (this.activeIndex = this.items.length - 1),
            void (this.activeDescendant = this.items[this.activeIndex].id)
          );
        0 !== this.activeIndex &&
          ((this.activeIndex =
            -1 === this.activeIndex
              ? this.items.length - 1
              : this.activeIndex - 1),
          (this.activeDescendant = this.items[this.activeIndex].id));
      },
      onArrowDown() {
        if (!this.open)
          return (
            (this.open = !0),
            (this.activeIndex = 0),
            void (this.activeDescendant = this.items[this.activeIndex].id)
          );
        this.activeIndex !== this.items.length - 1 &&
          ((this.activeIndex = this.activeIndex + 1),
          (this.activeDescendant = this.items[this.activeIndex].id));
      },
      onClickAway(e) {
        if (this.open) {
          const t = [
            "[contentEditable=true]",
            "[tabindex]",
            "a[href]",
            "area[href]",
            "button:not([disabled])",
            "iframe",
            "input:not([disabled])",
            "select:not([disabled])",
            "textarea:not([disabled])",
          ]
            .map((e) => `${e}:not([tabindex='-1'])`)
            .join(",");
          (this.open = !1), e.target.closest(t) || this.focusButton();
        }
      },
    };
  }),
  (window.Components.popoverGroup = function () {
    return {
      __type: "popoverGroup",
      init() {
        let e = (t) => {
          document.body.contains(this.$el)
            ? t.target instanceof Element &&
              !this.$el.contains(t.target) &&
              window.dispatchEvent(
                new CustomEvent("close-popover-group", {
                  detail: this.$el,
                })
              )
            : window.removeEventListener("focus", e, !0);
        };
        window.addEventListener("focus", e, !0);
      },
    };
  }),
  (window.Components.popover = function ({ open: e = !1, focus: t = !1 } = {}) {
    const i = [
      "[contentEditable=true]",
      "[tabindex]",
      "a[href]",
      "area[href]",
      "button:not([disabled])",
      "iframe",
      "input:not([disabled])",
      "select:not([disabled])",
      "textarea:not([disabled])",
    ]
      .map((e) => `${e}:not([tabindex='-1'])`)
      .join(",");
    return {
      __type: "popover",
      open: e,
      init() {
        t &&
          this.$watch("open", (e) => {
            e &&
              this.$nextTick(() => {
                !(function (e) {
                  const t = Array.from(e.querySelectorAll(i));
                  !(function e(i) {
                    void 0 !== i &&
                      (i.focus({
                        preventScroll: !0,
                      }),
                      document.activeElement !== i && e(t[t.indexOf(i) + 1]));
                  })(t[0]);
                })(this.$refs.panel);
              });
          });
        let e = (i) => {
          if (!document.body.contains(this.$el))
            return void window.removeEventListener("focus", e, !0);
          let n = t ? this.$refs.panel : this.$el;
          if (
            this.open &&
            i.target instanceof Element &&
            !n.contains(i.target)
          ) {
            let e = this.$el;
            for (; e.parentNode; )
              if (((e = e.parentNode), e.__x instanceof this.constructor)) {
                if ("popoverGroup" === e.__x.$data.__type) return;
                if ("popover" === e.__x.$data.__type) break;
              }
            this.open = !1;
          }
        };
        window.addEventListener("focus", e, !0);
      },
      onEscape() {
        (this.open = !1), this.restoreEl && this.restoreEl.focus();
      },
      onClosePopoverGroup(e) {
        e.detail.contains(this.$el) && (this.open = !1);
      },
      toggle(e) {
        (this.open = !this.open),
          this.open
            ? (this.restoreEl = e.currentTarget)
            : this.restoreEl && this.restoreEl.focus();
      },
    };
  }),
  (window.Components.radioGroup = function ({
    initialCheckedIndex: e = 0,
  } = {}) {
    return {
      value: void 0,
      active: void 0,
      init() {
        let t = Array.from(this.$el.querySelectorAll("input"));
        this.value = t[e]?.value;
        for (let e of t)
          e.addEventListener("change", () => {
            this.active = e.value;
          }),
            e.addEventListener("focus", () => {
              this.active = e.value;
            });
        window.addEventListener(
          "focus",
          () => {
            console.log("Focus change"),
              t.includes(document.activeElement) ||
                (console.log("HIT"), (this.active = void 0));
          },
          !0
        );
      },
    };
  }),
  (window.Components.tabs = function () {
    return {
      selectedIndex: 0,
      onTabClick(e) {
        if (!this.$el.contains(e.detail)) return;
        let t = Array.from(
            this.$el.querySelectorAll('[x-data^="Components.tab("]')
          ),
          i = Array.from(
            this.$el.querySelectorAll('[x-data^="Components.tabPanel("]')
          ),
          n = t.indexOf(e.detail);
        (this.selectedIndex = n),
          window.dispatchEvent(
            new CustomEvent("tab-select", {
              detail: {
                tab: e.detail,
                panel: i[n],
              },
            })
          );
      },
      onTabKeydown(e) {
        if (!this.$el.contains(e.detail.tab)) return;
        let t = Array.from(
            this.$el.querySelectorAll('[x-data^="Components.tab("]')
          ),
          i = t.indexOf(e.detail.tab);
        "ArrowLeft" === e.detail.key
          ? this.onTabClick({
              detail: t[(i - 1 + t.length) % t.length],
            })
          : "ArrowRight" === e.detail.key
          ? this.onTabClick({
              detail: t[(i + 1) % t.length],
            })
          : "Home" === e.detail.key || "PageUp" === e.detail.key
          ? this.onTabClick({
              detail: t[0],
            })
          : ("End" !== e.detail.key && "PageDown" !== e.detail.key) ||
            this.onTabClick({
              detail: t[t.length - 1],
            });
      },
    };
  }),
  (window.Components.tab = function (e = 0) {
    return {
      selected: !1,
      init() {
        let t = Array.from(
          this.$el
            .closest('[x-data^="Components.tabs("]')
            .querySelectorAll('[x-data^="Components.tab("]')
        );
        (this.selected = t.indexOf(this.$el) === e),
          this.$watch("selected", (e) => {
            e && this.$el.focus();
          });
      },
      onClick() {
        window.dispatchEvent(
          new CustomEvent("tab-click", {
            detail: this.$el,
          })
        );
      },
      onKeydown(e) {
        [
          "ArrowLeft",
          "ArrowRight",
          "Home",
          "PageUp",
          "End",
          "PageDown",
        ].includes(e.key) && e.preventDefault(),
          window.dispatchEvent(
            new CustomEvent("tab-keydown", {
              detail: {
                tab: this.$el,
                key: e.key,
              },
            })
          );
      },
      onTabSelect(e) {
        this.selected = e.detail.tab === this.$el;
      },
    };
  }),
  (window.Components.tabPanel = function (e = 0) {
    return {
      selected: !1,
      init() {
        let t = Array.from(
          this.$el
            .closest('[x-data^="Components.tabs("]')
            .querySelectorAll('[x-data^="Components.tabPanel("]')
        );
        this.selected = t.indexOf(this.$el) === e;
      },
      onTabSelect(e) {
        this.selected = e.detail.panel === this.$el;
      },
    };
  });

function initProjectOverlayClick() {
  document.addEventListener(
    "click",
    function (event) {
      if (!event.target.matches(".project-item-overlay")) return;
      const projectOverlay = event.target;
      const projectLink = projectOverlay.querySelector("a.project-link");
      projectLink.click();
    },
    false
  );
}

function initSlideCardClick() {
  document.addEventListener(
    "click",
    function (event) {
      if (!event.target.matches(".slide-card")) return;
      const slideCard = event.target;
      const cardLink = slideCard.querySelector("a.card-link");
      cardLink.click();
    },
    false
  );
}

function initModals() {
  const modalButtons = document.querySelectorAll(".trigger-modal");
  modalButtons.forEach((button) => {
    button.addEventListener("click", (event) => {
      const modalId = button.getAttribute("data-modal");
      const modalItem = document.getElementById(modalId);
      const closeMM = document.getElementById("closeMM");
      simulateClick(modalItem);
      simulateClick(closeMM);
      event.preventDefault();
    });
  });
}

function simulateClick(elem) {
   if ( !elem ) return false;
  // Create our event (with options)
  var evt = new MouseEvent("click", {
    bubbles: true,
    cancelable: true,
    view: window,
  });
  // If cancelled, don't dispatch our event
  var canceled = !elem.dispatchEvent(evt);
}

jQuery().ready(function ($) {
  // remove federal option from select
  $('[name="_sft_project-category[]"] option[value="federal"]').remove();

  $("#ajax-filters").on("click", "input", function () {
    var thisName = $(this).attr("name");

    if (thisName == "_sft_project-type[]") {
      // deselect other radios
      $(".sf-field-taxonomy-project-status .sf-label-radio").eq(0).click();
    }

    if (thisName == "_sft_project-status[]") {
      // deselect other radios
      $(".sf-field-taxonomy-project-type .sf-label-radio").eq(0).click();
    }
    $('.sf-field-taxonomy-project-category select option[value=""]').prop(
      "selected",
      "selected"
    );
    $(".sf-field-reset").removeClass("sf-option-active");
    $(".sf-field-taxonomy-project-category label").removeClass(
      "sf-option-active"
    );
  });

  $("#ajax-filters").on("change", "select", function () {
    $(".sf-field-taxonomy-project-category label").addClass("sf-option-active");
    $(".sf-field-taxonomy-project-status .sf-input-radio")
      .eq(0)
      .prop("checked", true)
      .trigger("change");
    $(".sf-field-taxonomy-project-type .sf-input-radio")
      .eq(0)
      .prop("checked", true)
      .trigger("change");
    $(".sf-field-reset").removeClass("sf-option-active");
  });

  $("#ajax-filters").on("click", ".search-filter-reset", function () {
    $(".sf-field-reset").addClass("sf-option-active");
  });
});

(function () {
  var doc = document.documentElement;
  var w = window;

  var prevScroll = w.scrollY || doc.scrollTop;
  var curScroll;
  var direction = 0;
  var prevDirection = 0;

  var header = document.getElementById("headerMain");
  var headerMobile = document.getElementById("headerMobile");

  var checkScroll = function () {
    /*
     ** Find the direction of scroll
     ** 0 - initial, 1 - up, 2 - down
     */

    curScroll = w.scrollY || doc.scrollTop;
    if (curScroll > prevScroll) {
      //scrolled up
      direction = 2;
    } else if (curScroll < prevScroll) {
      //scrolled down
      direction = 1;
    }

    if (direction !== prevDirection) {
      toggleHeader(direction, curScroll);
    }

    //  console.log(curScroll);
    if (curScroll > 98) {
      headerMain.classList.add("minimize");
    } else {
      headerMain.classList.remove("minimize");
    }

    prevScroll = curScroll;
  };

  var toggleHeader = function (direction, curScroll) {
    if (direction === 2 && curScroll > 60) {
      if (!headerMobile.classList.contains("mobile-menu-open")) {
        headerMobile.classList.add("minimize");
        prevDirection = direction;
        const closeMM = document.getElementById("closeMM");
        simulateClick(closeMM);
      }
    } else if (direction === 1) {
      headerMobile.classList.remove("minimize");
      prevDirection = direction;
    }
  };

  window.addEventListener("scroll", checkScroll);
})();

jQuery().ready(function ($) {
  if (jQuery("#form_employeesignup").length) {
    setTimeout(function () {
      setTimeout(function () {
        jQuery("#form_employeesignup .frm_add_form_row").click();
      }, 250);

      setTimeout(function () {
        jQuery("#form_employeesignup .frm_add_form_row").click();
      }, 500);

      setTimeout(function () {
        jQuery("#form_employeesignup .frm_add_form_row").click();
      }, 750);
    }, 250);
  }
});

jQuery(document).ready(function ($) {
  let $search_open = $("button#open_search_form");
  let $search_close = $("button#close_search_form");
  let $hold_menu_and_search = $("#hold_menu_and_search");
  let $search = $("#s");

  $search_open.click(function () {
    $hold_menu_and_search.addClass("search_active");
    $search.focus();
  });
  $search_close.click(function () {
    $hold_menu_and_search.removeClass("search_active");
    $search_open.focus();
  });
});

(() => {
  const accordions = document.querySelectorAll(".accordion");

  const getElementHeight = (element) => {
    const styles = window.getComputedStyle(element);
    const marginTop = parseInt(styles.marginTop);
    const marginBottom = parseInt(styles.marginBottom);
    const paddingTop = parseInt(styles.paddingTop);
    const paddingBottom = parseInt(styles.paddingBottom);
    const height = element.offsetHeight;
    return marginTop + marginBottom + paddingTop + paddingBottom + height;
  };

  const openAccordion = (accordion) => {
    const content = accordion.querySelector(".accordion__content");
    accordion.classList.add("accordion__active");

    // Calculate the total height by summing up the heights of child elements
    let totalHeight = 0;
    const childElements = content.children;
    for (let i = 0; i < childElements.length; i++) {
      totalHeight += getElementHeight(childElements[i]);
    }

    // Add the padding of the box itself
    const paddingTop = parseInt(window.getComputedStyle(content).paddingTop);
    const paddingBottom = 32; // Use the specific bottom padding value of 32px
    totalHeight += paddingTop + paddingBottom;

    content.style.maxHeight = totalHeight + "px";
  };

  const closeAccordion = (accordion) => {
    const content = accordion.querySelector(".accordion__content");
    accordion.classList.remove("accordion__active");
    content.style.maxHeight = null;
  };

  const updateAccordionHeight = () => {
    accordions.forEach((accordion) => {
      if (accordion.classList.contains("accordion__active")) {
        openAccordion(accordion);
      }
    });
  };

  accordions.forEach((accordion) => {
    const intro = accordion.querySelector(".accordion__intro");
    const content = accordion.querySelector(".accordion__content");

    intro.onclick = () => {
      if (content.style.maxHeight) {
        closeAccordion(accordion);
      } else {
        accordions.forEach((accordion) => closeAccordion(accordion));
        openAccordion(accordion);
      }
    };
  });

  // Update accordion height on window resize
  window.addEventListener("resize", updateAccordionHeight);
})();

jQuery(document).ready(function ($) {
  $("body").on("click", ".clickable-box", function (e) {
    const box = $(this);
    let a_tag = box.find("a.button:first");
    if ( !a_tag.length ) {
      a_tag = $(this).find("a:first");
    }
    const no_text_selected = !window.getSelection().toString();
    let href = null;

    if (a_tag) {
      href = a_tag.attr("href");
      if (no_text_selected && href) {
        a_tag[0].click();
      }
    }
  });

  // check each clickable box for a link. if none, change the cursor
  $(".clickable-box").each(function () {
    let href = $(this).find("a.button:first");
    if ( !href.length ) {
      href = $(this).find("a:first");
    }
    if (!href.length) {
      $(this).css("cursor", "initial");
    }
  });

  $(".sf-input-select").select2({
    minimumResultsForSearch: 100000,
  });
});
// works when logged in, not when logged out
jQuery(document).ready(function ($) {
   
  $(window).on("load resize", function () {
    $('a[href*="#"]')
      .not('.shiftnav a[href*="#"]')
      .not(".skip-link")
      .not(".tab_title")
      .not('[href="#"]')
      .not('[role="tab"]')
      .not('[href="#0"]')
      .not(".social-link")
      .click(function (t) {
         
        let current_url = window.location.hostname + window.location.pathname;
        let clicked_url = $(this)[0].hostname + $(this)[0].pathname;
        if (current_url == clicked_url) {
          //is on page anchor link

          var headerHeight = $("#headerInner").outerHeight();

          var adminBarHeight = $("#wpadminbar").outerHeight() ?? 0;
          t.preventDefault();
          var totalHeight = headerHeight + adminBarHeight + 100;
          if (
            location.pathname.replace(/^\//, "") ==
              this.pathname.replace(/^\//, "") &&
            location.hostname == this.hostname
          ) {
            var e = $(this.hash);
            e = e.length ? e : $("[name=" + this.hash.slice(1) + "]");
            if (e.length) {
              let scrollTo = e[0].offsetTop - totalHeight;


              // isAnimating = true;
              // console.log("isAnimating true");
              // $("html, body").animate({ scrollTop: e.offset().top - totalHeight }, 1e3, function () {
              //     isAnimating = false;
              //     console.log("isAnimating false1");
              // });
              // var page = $("html, body");

              // page.on("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove", function() {
              //     page.stop();
              // });

              // page.animate({ scrollTop: e.offset().top - totalHeight }, 800, function() {
              //     page.stop();
              //     page.off("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove");
              // });

              window.scrollTo({ top: scrollTo, behavior: "smooth" });


            }
          }
        }
      });
  });
  // if theres a hashtag in the URL, smooth scroll to it
  if (window.location.hash) {
    window.scrollTo(0, 0);
    var hash = window.location.hash;
    var element_to_scroll = jQuery(hash);
    if (element_to_scroll.length) {
      let extra_device_scroll = -45;
      if (jQuery(window).width() < 1025) {
        extra_device_scroll = -45;
      } else {
        extra_device_scroll = -125;
      }
      let admin_bar_height = jQuery("#wpadminbar").outerHeight();
      let total_scroll_amount =
        element_to_scroll.offset().top + extra_device_scroll - admin_bar_height;
      jQuery("html, body").animate({ scrollTop: total_scroll_amount }, 1000);
    }
  }


});
